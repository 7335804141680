import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ScheduleOnlyGrid } from "./s25.academic.grids.util";
import { Bind } from "../../decorators/bind.decorator";
import { S25Util } from "../../util/s25-util";

@Component({
    selector: "s25-ng-academic-grids-filter",
    template: `
        <h4>Filters</h4>
        <form class="options" (keydown.enter)="applyFilters()">
            @for (filter of filters; track filter.label) {
                <div>
                    <label>{{ filter.label }}</label>
                    @if (filter.type === "text") {
                        <input
                            [(ngModel)]="filter.value"
                            [id]="filter.label + '-filter'"
                            type="text"
                            class="c-input"
                            name="{{ filter.label }}-name"
                            [attr.aria-label]="filter.label"
                        />
                    } @else if (filter.type === "numberRange") {
                        <div class="number-range">
                            <input
                                [(ngModel)]="filter.value().min"
                                type="number"
                                placeholder="min"
                                name="{{ filter.label }}-min"
                                class="c-input"
                            />
                            <span> - </span>
                            <input
                                [(ngModel)]="filter.value().max"
                                type="number"
                                placeholder="max"
                                name="{{ filter.label }}-max"
                                class="c-input"
                            />
                        </div>
                    } @else if (filter.type === "contact") {
                        <s25-contact-dropdown [(chosen)]="filter.value" />
                    } @else if (filter.type === "dropdownMultiselect") {
                        <s25-ng-generic-multiselect-dropdown
                            [items]="filter.data.items"
                            [chosen]="filter.value()"
                            (done)="filter.value.set($event.slice())"
                            [placeholder]="filter.data.placeholder"
                            [emptyText]="'None available'"
                            [searchEnabled]="true"
                        />
                    } @else if (filter.type === "yesNo") {
                        <s25-toggle-button
                            [modelValue]="filter.value()"
                            (modelValueChange)="filter.value.set($event)"
                        />
                    }
                </div>
            }
        </form>
        <div class="buttons">
            <s25-ng-button [type]="'outline'" [onClick]="applyFilters" [replaceClickEvent]="false">
                Apply
            </s25-ng-button>
            <s25-ng-button [type]="'danger--outline'" [onClick]="clearFilters"> Clear </s25-ng-button>
        </div>
    `,
    styles: `
        :host {
            --background: #fafaf9;
            background: var(--background);

            display: grid;
            gap: 1rem;
            padding: 0.5rem;
        }

        ::ng-deep .nm-party--on s25-ng-academic-grids-filter {
            --background: #3c3d46;
        }

        h4 {
            font-size: 1.15em;
            text-align: center;
        }

        .options {
            display: grid;
            gap: 0.5rem;
        }

        .options > * {
            display: grid;
            grid-template-columns: 7em auto;
            align-items: center;
        }

        .buttons {
            display: flex;
            gap: 0.5rem;
            justify-content: right;
        }

        .number-range {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }

        .number-range input {
            max-width: 5em;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AcademicGridsFilterComponent {
    @Input({ required: true }) filters: ScheduleOnlyGrid.Filter[];

    @Output() apply = new EventEmitter<void>();
    @Output() clear = new EventEmitter<void>();

    @Bind
    async applyFilters() {
        await S25Util.delay(0); // Wait for any dropdown to close and emit
        this.apply.emit();
    }

    @Bind
    clearFilters() {
        for (const filter of this.filters) S25AcademicGridsFilterComponent.clearFilter(filter);
        this.clear.emit();
    }

    public static clearFilter(filter: ScheduleOnlyGrid.Filter): void {
        if (filter.clear) filter.clear();
        else filter.value.set(undefined);
    }
}
