import { Proto } from "../../pojo/Proto";
import { GridsService } from "../../services/grids.service";
import { DowGrid } from "../s25-virtual-grid/s25.dow.grid.component";
import { Grid } from "../s25-virtual-grid/s25.virtual.grid.component";
import { EventSummary, EventSummaryService } from "../s25-swarm-schedule/s25.event.summary.service";
import { S25Util } from "../../util/s25-util";
import { BOARD_CONST } from "../s25-swarm-schedule/s25.board.const";
import { BoardUtil } from "../s25-swarm-schedule/s25.board.util";
import { S25Const } from "../../util/s25-const";
import { CustomAttributes } from "../../pojo/CustomAttributes";
import { DefinitelyArray } from "../../pojo/Util";
import { computed, signal, Signal, WritableSignal } from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { RecordType } from "../s25-event/ProfileI";

export namespace ScheduleOnlyGrid {
    import Unwrap = Proto.Unwrap;
    import DowNumber = Proto.DowNumber;
    import TimeSet = EventSummary.TimeSet;
    import ISODateString = Proto.ISODateString;

    export type DataSource = DowGrid.DataSource<HeaderData, RowData, ItemData>;

    export type Data = DowGrid.Data<HeaderData, RowData, ItemData>;
    export type Row = Grid.Row<RowData>;
    export type Item = DowGrid.Item<ItemData>;

    export type HeaderData = {};
    export type RowData = TooltipData & { header: string; profileId: number };
    export type ItemData = DowGrid._CustomItemData<DowGrid.CustomItemData> &
        TooltipData & {
            profileId: number;
            boundProfiles: number[];
            adHoc: boolean;
            instructorConflict?: boolean;
            startDate: ISODateString;
            endDate: ISODateString;
            occurrences: EventData["profile"][number]["reservation"];
        };

    export type TooltipData = {
        eventId: number;
        eventName: string;
        eventTitle: string;
        reference: string;
        expectedHeadCount: number;
        registeredHeadCount: number;
        requirements: DefinitelyArray<EventData["requirement"]>;
        subjectCode: string;
        instructor?: DefinitelyArray<EventData["role"]>[number];
        courseReferenceNumber?: string;
        subTerm?: string;
        sectionType?: string;
        locations: { id: number; name: string }[];
    };

    type EventData = Unwrap<Awaited<ReturnType<typeof GridsService.getData>>>["events"][number];

    export type Filter = {
        label: string;
        type: "text" | "numberRange" | "contact" | "dropdownMultiselect" | "yesNo";
        value: WritableSignal<any>;
        active: Signal<boolean>;
        clear?: () => void;
        text: Signal<string>;
        filter: (row: Row | Item) => boolean;
        data?: unknown;
    };

    export function getData(events: EventData[]): { rows: Row[]; items: Item[] } {
        const profiles = S25Util.array.flatten(
            events.map((event) => event.profile.map((profile) => ({ event, profile }))),
        );

        const rows: Row[] = getRows(profiles);
        const items: Item[] = getItems(profiles);

        return { rows, items };
    }

    export function getRows(profiles: { event: EventData; profile: EventData["profile"][number] }[]): Row[] {
        const rows = profiles.map(mapProfileToRow);
        sortRowsAsEvents(rows);
        return rows;
    }

    export function mapProfileToRow(data: { event: EventData; profile: EventData["profile"][number] }): Row {
        let header = data.event.event_name;
        if (data.event.profile.length > 1) header += ` (${data.profile.profile_name})`;
        return {
            id: data.profile.profile_id,
            data: {
                header,
                profileId: data.profile.profile_id,
                ...getTooltipData(data.event, data.profile),
            },
        };
    }

    export function sortRowsAsEvents(rows: Row[]): void {
        rows.sort((a, b) => {
            if (!a.data.eventName) return 1;
            if (!b.data.eventName) return -1;
            if (!a.data.eventName && !b.data.eventName) return 0;
            return a.data.eventName.localeCompare(b.data.eventName);
        });
    }

    export function sortRowsAsInstructors(rows: Row[]): void {
        rows.sort((a, b) => {
            if (!a.data?.instructor?.contact) return 1;
            if (!b.data?.instructor?.contact) return -1;
            if (!a.data?.instructor?.contact && !b.data?.instructor?.contact) return 0;
            return a.data.instructor?.contact?.contact_name?.localeCompare(b.data.instructor?.contact?.contact_name);
        });
    }

    export function getItems(profiles: { event: EventData; profile: EventData["profile"][number] }[]): Item[] {
        const items = S25Util.array.flatten(profiles.map(mapProfileToItems));
        linkItems(items);

        return items;
    }

    export function mapProfileToItems(
        data: { event: EventData; profile: EventData["profile"][number] },
        index: number,
        profiles: { event: EventData; profile: EventData["profile"][number] }[],
    ): Item[] {
        const orderedDowPatterns = BoardUtil.getDowPatterns(data.profile.reservation);
        const timeSets = EventSummaryService._adhocSummaryModel(
            data.profile.reservation,
            -1,
            99_999,
            orderedDowPatterns,
            true,
            true,
        );

        return timeSets.map((set, i) => {
            return {
                id: `${data.profile.profile_id}-${i}`,
                top: (index / profiles.length) * 100,
                height: 100 / profiles.length,
                draggable: false,
                linkedItems: new Set(),
                data: getItemData(data.event, data.profile, set),
            } as Item;
        });
    }

    export function getItemData(event: EventData, profile: EventData["profile"][number], timeSet: TimeSet): ItemData {
        const earliestReservation = profile.reservation.reduce(
            (earliest, res) => (res.reservation_start_dt < earliest ? res.reservation_start_dt : earliest),
            profile.reservation[0].reservation_start_dt,
        );
        const lastReservation = profile.reservation.reduce(
            (latest, res) => (res.reservation_end_dt > latest ? res.reservation_end_dt : latest),
            profile.reservation[0].reservation_end_dt,
        );

        return {
            profileId: profile.profile_id,
            boundProfiles: profile.binding_reservation?.map((r) => r.bound_reservation_id) ?? [],
            adHoc: profile.rec_type_id !== RecordType.RecurrenceGrammar,
            dow: getDowFromTimeSet(timeSet),
            startHour: S25Util.date.timeToHours(timeSet.startTime),
            endHour: S25Util.date.timeToHours(timeSet.endTime),
            startDate: earliestReservation,
            endDate: lastReservation,
            occurrences: profile.reservation,
            ...getTooltipData(event, profile),
        };
    }

    // Link bound items with the same patterns and times
    export function linkItems(items: Item[]) {
        const itemsByProfile = S25Util.array.groupBy(items, (item) => item.data.profileId);

        const matches = (a: Item, b: Item) =>
            a.data.dow === b.data.dow && a.data.startHour === b.data.startHour && a.data.endHour === b.data.endHour;

        for (const item of items) {
            if (!item.data.boundProfiles?.length) continue;
            for (const profile of item.data.boundProfiles) {
                const boundItems = itemsByProfile[profile];
                for (const binding of boundItems ?? []) {
                    if (!matches(item, binding)) return;
                    item.linkedItems.add(binding.id);
                    binding.linkedItems.add(item.id);
                }
            }
        }
    }

    export function getTooltipData(event: EventData, profile: EventData["profile"][number]): TooltipData {
        const roleById = new Map(S25Util.array.forceArray(event.role).map((role) => [role.role_id, role]));
        const attributeById = new Map(
            S25Util.array.forceArray(event.custom_attribute).map((ca) => [ca.attribute_id, ca]),
        );
        const primaryOrg = S25Util.array.forceArray(event.organization).find((org) => org.primary === "T");

        const locations: TooltipData["locations"] = [];
        const locationIds = new Set<number>();
        for (const res of profile.reservation) {
            for (const spaceRes of res.space_reservation || []) {
                if (locationIds.has(spaceRes.space_id)) continue;
                locationIds.add(spaceRes.space_id);
                locations.push({ id: spaceRes.space_id, name: spaceRes.space.space_name });
            }
        }

        return {
            eventId: event.event_id,
            eventName: event.event_name,
            eventTitle: event.event_title,
            reference: event.event_locator,
            expectedHeadCount: Number(profile.expected_count),
            registeredHeadCount: Number(profile.registered_count),
            requirements: S25Util.array.forceArray(event.requirement),
            subjectCode: primaryOrg?.organization_name,
            instructor: roleById.get(S25Const.instructorRole.event),
            courseReferenceNumber: attributeById.get(CustomAttributes.system.uniqueSectionId.id)?.attribute_value,
            subTerm: attributeById.get(CustomAttributes.system.subTerm.id)?.attribute_value,
            sectionType: attributeById.get(CustomAttributes.system.sectionType.id)?.attribute_value,
            locations,
        };
    }

    export function getDowFromTimeSet(timeSet: EventSummary.TimeSet): string {
        if (timeSet.pattern) {
            const profileCode = EventSummaryService.adhocSummaryModelToProfileCode(timeSet);
            const dow = S25Const.dows.filter((dow) => profileCode.includes(dow));
            return BoardUtil.daysOfWeekMap(dow.join(" ") + " ");
        } else {
            let dows = timeSet.map((occ) => S25Util.date.parseDropTZ(occ.reservation_start_dt).getDay() as DowNumber);
            dows = S25Util.array.unique(dows).sort();
            const dowsAbbr = dows.map((dow) => BOARD_CONST.dowInt2Abbr[dow]);
            return BoardUtil.daysOfWeekMap(dowsAbbr.join(" ") + " ");
        }
    }

    export function getAllDows(items: { data?: { dow: string } }[]): { all: string[]; included: string[] } {
        // Make sure all present dow patterns are included
        const all = [...BOARD_CONST.allDows];
        const allDowsSet = new Set(all);
        const includedDowsSet = new Set<string>();
        for (let item of items) {
            if (!item.data) continue;
            includedDowsSet.add(item.data.dow);
            if (!allDowsSet.has(item.data.dow)) {
                all.push(item.data.dow);
                allDowsSet.add(item.data.dow);
            }
        }

        const included = Array.from(includedDowsSet);
        const allDowsOrder = new Map(all.map((dow, i) => [dow, i]));
        included.sort((a, b) => allDowsOrder.get(a) - allDowsOrder.get(b));

        return { all, included };
    }

    export function createFilter(options: {
        type: Filter["type"];
        label: string;
        transform?: (item: Row | Item) => any;
        data?: unknown;
    }): Filter {
        const { type, label, transform, data } = options;
        switch (type) {
            case "text":
                const textValue = signal<string>(undefined);
                return {
                    label,
                    type,
                    value: textValue,
                    active: computed(() => !!textValue()),
                    text: computed(() => textValue() as string),
                    filter: (row) => !!transform(row)?.toLowerCase().includes(textValue().toLowerCase()),
                };
            case "numberRange":
                const numberRange = signal<{ min: WritableSignal<number>; max: WritableSignal<number> }>({
                    min: signal(null),
                    max: signal(null),
                });
                return {
                    label,
                    type,
                    value: numberRange,
                    active: computed(() => numberRange().min() !== null || numberRange().max() !== null),
                    clear: () => numberRange.set({ min: signal(null), max: signal(null) }),
                    text: computed(() => {
                        const [min, max] = [numberRange().min(), numberRange().max()];
                        if (min === null) return `< ${max}`;
                        if (max === null || max < min) return `> ${min}`;
                        return `${min} - ${max}`;
                    }),
                    filter: (row) => {
                        const [min, max] = [numberRange().min(), numberRange().max()];
                        const value = transform(row);
                        if (isNaN(value)) return false;
                        if (min === null) return value <= max;
                        if (max === null || max < min) return value >= min;
                        return value >= min && value <= max;
                    },
                };
            case "contact":
                const contactValue = signal<DropDownItem>(undefined);
                return {
                    label,
                    type,
                    value: contactValue,
                    active: computed(() => !!contactValue()),
                    text: computed(() => contactValue().itemName),
                    filter: (row) => {
                        return transform(row) === contactValue().itemId;
                    },
                };
            case "dropdownMultiselect":
                const multiselectValue = signal<DropDownItem[]>(undefined);
                const multiselectIds = computed(() => new Set(multiselectValue().map((item) => String(item.itemId))));
                return {
                    label,
                    type,
                    value: multiselectValue,
                    active: computed(() => !!multiselectValue()?.length),
                    text: computed(() =>
                        multiselectValue()
                            .map((item) => item.itemName)
                            .join(", "),
                    ),
                    filter: (row) => {
                        return transform(row).some((id: number | string) => multiselectIds().has(String(id)));
                    },
                    data,
                };
            case "yesNo":
                const yesNoValue = signal<boolean>(undefined);
                return {
                    label,
                    type,
                    value: yesNoValue,
                    active: computed(() => yesNoValue() !== undefined),
                    text: computed(() => (yesNoValue() ? "Yes" : "No")),
                    filter: (row) => transform(row) === yesNoValue(),
                };
        }
    }
}
